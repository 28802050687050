import React, { useState, useEffect, useRef} from 'react';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ChippedMultiselect from './ChippedMultiselect';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './App.css';

import VacancyCellRenderer from './VacancyCellRenderer';
import RemoteRenderer from './RemoteRenderer';
import MobileRenderer from './MobileRenderer';


import vacancies from "./generated/data.json";

const skillTree = require("./commonSettings-git/skillTree");

const vacArr = Object.values(vacancies).filter((item) => { return item['Category'].indexOf('DELETED') === -1});

const possibleCategories = skillTree.map((value, key) => Object.keys(value)[0]);

const candidateCategories = vacArr.map(c => c.Category);
const uniqCandidateCategories = [...new Set(candidateCategories)];
const presentCategories = possibleCategories.filter(cat => uniqCandidateCategories.indexOf(cat) !== -1)
const categoryListForSelect = [""].concat(presentCategories);

const App = () => {
    const [gridApi, setGridApi] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [chosenSearch, setChosenSearch] = useState("");
    const [isRemote, setIsRemote] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [location, setLocation] = useState("");
    const gridWrapperRef = React.useRef();

    const selectedTagsRef = useRef();
    selectedTagsRef.current = selectedTags;

    useEffect(() => {
      if(gridApi) {
        gridApi.paginationGoToPage(0);
      }
    }, [selectedCategory, chosenSearch, selectedTags, isRemote, gridApi]);

    function onGridReady(params) {
        setGridApi(params.api);
    }

    let [rowData] = useState(vacArr);
    let tagOptions = rowData.map(c => c.Tags)
        .filter(c => typeof(c) === "string" && c !== '')
        .flatMap(s => multiStringToArray(s));

    function onFilterTextBoxChanged(e) {
      setChosenSearch(e.target.value)
      gridApi.setQuickFilter(e.target.value);
    }

    function updateFileString(params) {
        params.data['ClicksCount']++;
        window.open(params.data['Link'].indexOf('?source=') > -1 ? params.data['Link'] : params.data['Link'] + '?source=GamesJobFair', '_blank').focus();

        window.goatcounter.count({
            path:  params.data['Link'],
            title: params.data['Title'],
            event: true,
        })
        //const vRecordsStrings = rowData.map((record, i) => '"' + i + '" : ' + JSON.stringify(record));
        //const template = `{
        //    <%- vRecordsString %>
        //}
        //`;
        //var fileContentString = ejs.render(template, {vRecordsString: vRecordsStrings.join(",\n")});

    }

    function onLocationSelected(e) {
      setLocation(e.target.value);
      const wholeTableFilterModel = gridApi.getFilterModel();

      wholeTableFilterModel.Location = {
        type: 'contains',
        filter: e.target.value
      }

      gridApi.setFilterModel(wholeTableFilterModel);
    }

    function onTagsChanged(e, value, reason) {
        console.log(value);
      setSelectedTags(value);
      selectedTagsRef.current = value;

      gridApi.onFilterChanged();
      // const wholeTableFilterModel = gridApi.getFilterModel();
      // wholeTableFilterModel.Tags = {
      //   type: 'contains',
      //   filter: value
      // }
      //
      // gridApi.setFilterModel(wholeTableFilterModel);
    }

    function multiStringToArray(sourceString) {
        let extracted = sourceString.split(";");
        if (extracted.length === 1) {
          extracted = sourceString.split(',');
        }
        const result = extracted.map(s => s.trim());
        return result;
     };

     function onRemoteSelected(e){
         setIsRemote(!isRemote);
         const isRemoteFilter = isRemote ? '' : 'Yes';
         const wholeTableFilterModel = gridApi.getFilterModel();

         wholeTableFilterModel.isRemote = {
           type: 'equals',
           filter: isRemoteFilter
         }

         gridApi.setFilterModel(wholeTableFilterModel);
     }

    function onCategorySelected(e) {
      const selectedCategoryStr = e.target.value;
      setSelectedCategory(selectedCategoryStr);

      const wholeTableFilterModel = gridApi.getFilterModel();

      wholeTableFilterModel.Category = {
        type: 'equals',
        filter: selectedCategoryStr
      }

      gridApi.setFilterModel(wholeTableFilterModel);
    }

    function onPaginationChange(e) {
      console.log("pagination changed", e);
      if(e.newPage) {
        gridWrapperRef.current.scrollIntoView(true);
      }
    }

    // Due to https://github.com/ag-grid/ag-grid/issues/3160 we can't use automatic row height
    // with React cell renderer
    function calcRowHeight() {
      // cloning the CSS rule here, go to index.css for the reference
      if(window.innerWidth >= 640) {
        // enough width for the right block to be on the same line
        return 70;
      } else {
        return 122;
      }
    }


    function isExternalFilterPresent() {
      return true;
      // return selectedSpecialities.length > 0;
    }

    function doesExternalFilterPass(node) {
      // console.log("selectedSpecialitiesRef.current: ", selectedSpecialitiesRef.current);
      //const selSpecs = selectedSpecialitiesRef.current;
      // const testTarget = node.data.specialities;
      // if(testTarget === undefined) {
      //   // candidates with no specialities on the record to be shown only when no specs are selected
      //   if(selSpecs.length === 0) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }
      // const matchedFilters = selSpecs.filter(s => testTarget.indexOf(s) !== -1);
      // const res = matchedFilters.length === selSpecs.length;
      // return res;
      // return node.data.specialities.contains("Gameplay");
      const tags = selectedTagsRef.current;
      const testTarget = node.data.Tags;
      if(testTarget === undefined ) {
        // candidates with no specialities on the record to be shown only when no specs are selected
        if(tags.length === 0) {
                return true;
        } else {
                return false;
        }
      }
      const matchedFilters = [...tags.filter(s => testTarget.indexOf(s) !== -1)];
      const res = matchedFilters.length === tags.length;
      return res;
    }

    function isMobile(){
        return window.innerWidth < 640;

    }

    const categorySelectItems = categoryListForSelect.map((catStr, idx) =>
    {
      return catStr === "" ?
      <MenuItem value={catStr} key={idx}><em>All Categories</em></MenuItem>
      :
      <MenuItem value={catStr} key={idx}>{catStr}</MenuItem>

    });

    rowData.sort((item, itemCompare) => {
        return item.CompanyName > itemCompare.companyName;
    })


    return (
      <div id="whole-app">
          <div className="filter-block">
            <div className="filter-control">
              <FormControl className="filter-control" variant="outlined">
                <InputLabel id="selectCategoryLabel" htmlFor="selectCategorySelect">Category</InputLabel>
                <Select
                  id="selectCategorySelect"
                  labelId="selectCategoryLabel"
                  className="filter-select-box"
                  label="Category"
                  value={selectedCategory}
                  onChange={onCategorySelected}
                >
                  {categorySelectItems}
                </Select>
              </FormControl>
            </div>
            <div className="filter-control">
              <FormControl variant="outlined">
                <ChippedMultiselect
                  id="specialitiesMultiselect"
                  labelText="Tags"
                  options={tagOptions}
                  onChange={onTagsChanged}
                  value={selectedTags}
                />
              </FormControl>
            </div>

            <div className="filter-control">
              <FormControl>
                <TextField
                  id="keywordsInput"
                  className="filter-select-box"
                  variant="outlined"
                  value={location}
                  label="Location"
                  type="search"
                  onChange={onLocationSelected}
                />
              </FormControl>
            </div>

            <div className="filter-control">
              <FormControl>
                <TextField
                  id="keywordsInput"
                  className="filter-select-box"
                  variant="outlined"
                  value={chosenSearch}
                  label="Keywords search"
                  type="search"
                  onChange={onFilterTextBoxChanged}
                />
              </FormControl>
            </div>
            <div className="filter-control checkbox">
            <FormControl className="filter-control" variant="outlined">
            <label className="container">
              <input className={isMobile()} type="checkbox"
                   onChange={onRemoteSelected} />
              <p>Is Remote</p>
              <span className="checkmark"></span>
              </label>
            </FormControl>
            </div>
          </div>
          <div id="grid-wrapper" className="ag-theme-alpine" ref={gridWrapperRef}>
            <AgGridReact
                rowData={rowData}
                onGridReady={onGridReady}
                enableBrowserTooltips={true}
                enableCellTextSelection={true}
                pagination={true}
                paginationPageSize={isMobile() ? 6 : 10}
                onPaginationChanged={onPaginationChange}
                onCellClicked={(params) => {updateFileString(params);}}
                domLayout="autoHeight"
                rowHeight={calcRowHeight()}
                rowClass="grid-row"
                isExternalFilterPresent={isExternalFilterPresent}
                doesExternalFilterPass={doesExternalFilterPass}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapText: true,
                  flex: 1
                }}
                >
                <AgGridColumn flex={3} field="Title" hide={isMobile()} wrapText={true} ></AgGridColumn>
                <AgGridColumn flex={2} field="customRendered" hide={isMobile()} headerName="Remote" cellRendererFramework={RemoteRenderer} ></AgGridColumn>
                <AgGridColumn flex={1} field="isRemote" hide={true} ></AgGridColumn>
                <AgGridColumn flex={1} field="CompanyName" hide={true} ></AgGridColumn>
                <AgGridColumn flex={2} field="Category" hide={isMobile()}></AgGridColumn>
                <AgGridColumn flex={4} field="CompanyName" hide={isMobile()} headerName="Company Name" cellRendererFramework={VacancyCellRenderer} ></AgGridColumn>
                <AgGridColumn flex={2} field="Location" hide={isMobile()} headerName="Location" ></AgGridColumn>
                <AgGridColumn flex={2} field="Tags" hide={isMobile()}></AgGridColumn>

                <AgGridColumn field="customRendered" hide={!isMobile()} cellRendererFramework={MobileRenderer}></AgGridColumn>
            </AgGridReact>
          </div>
      </div>
    );
};

export default App;
