const skillTree = [
    {
        "Programming & Engineering": {
            specialities: [
                "Game Development",
                "Client Development",
                "Frontend Development ",
                "Backend Development",
                "Fullstack Development ",
                "Audio Development",
                "Console Development",
                "Engine Development",
                "Tools Development ",
                "Graphics Development",
                "MR Development",
                "AR Development",
                "VR Development",
                "Mobile Development",
                "PC Development",
                "Online Game Development",
                "UI Development",
                "DevOps Engineering",
                "SysOps Engineering",
                "LiveOps Engineering",
                "Build Engineering",
                "Site Reliability Engineering SRE",
                "Server Development",
                "Cloud Architecture",
                "AI Development",
                "Audio Engineering",
                "SDK Development",
                "Gameplay Development",
                "Web Development",
                "Software Architecture",
                "Backend Architecture"
            ],
            skills: [
                ".NET",
                "3D Engine",
                "Ad monetization",
                "AD-LDS",
                "Android SDK",
                "Android Studio",
                "Angular",
                "Anka",
                "Ansible",
                "API",
                "Apple App",
                "Apple Maps",
                "AR",
                "AR Core",
                "AR Kit",
                "Atlassian toolchain IAM",
                "Audio occlusion",
                "Audio prioritization",
                "Audiokinetic Wwise",
                "Auth",
                "AutoCAD",
                "AWS",
                "Azure",
                "Assembler",
                "Backend Development",
                "Bash",
                "Behaviour Trees",
                "C",
                "C#",
                "C++",
                "CD",
                "CentOS",
                "CG",
                "CI",
                "Cloud computing",
                "Cloud engineering",
                "Cloud platforms",
                "Cloud technologies",
                "Cloud-based services",
                "Clusters",
                "CMake",
                "Cocos",
                "Cocos2d-x",
                "Cocos3d-x",
                "Computer Graphics",
                "Computer-Aided Design (CAD)",
                "Confluence Game features",
                "Container services",
                "CoreData",
                "CSS",
                "Cucumber BDD",
                "Dart",
                "Dialogue systems",
                "Direct X",
                "Django",
                "Docker",
                "Dropbox SDK",
                "Entity-Component-Systems (ECS) framework",
                "EQS",
                "Express JS",
                "Elixir",
                "Facebook API",
                "FastAPI",
                "Fastlane",
                "Fedora",
                "Firebase",
                "Flask",
                "Flutter",
                "GCP",
                "Geometry",
                "Gherkin",
                "Git",
                "Github",
                "GLSL",
                "Go",
                "Google BigQuery",
                "Google Maps & API",
                "Google play",
                "GPU",
                "Gradle",
                "GraphQL",
                "Hadoop",
                "HealthKit",
                "Hero",
                "HLSL",
                "HTML",
                "Hybrid Apps",
                "HydraAsync & GCD",
                "IAM",
                "IAP & Subscriptions",
                "Iconic",
                "Instagram API",
                "IntelliJ",
                "Java",
                "Java EE",
                "JavaScript JS",
                "Jenkins",
                "Jenkins Pipelines",
                "Jira",
                "JVM",
                "Kotlin",
                "Kubernetes",
                "Lambda",
                "LDAP",
                "Linear Algebra",
                "Linux",
                "Lottie",
                "Lua",
                "Matlab",
                "Maven",
                "Microservices",
                "Microsoft (MS) SQL Server",
                "MMO",
                "MongoDB",
                "Moya & AFNetworking",
                "MR",
                "MVVM",
                "MySQL",
                "Native iOS Development",
                "Nginx",
                "Node.js",
                "NoSQL",
                "Notifications",
                "OAuth",
                "Obejective C",
                "Open GL",
                "OpenID Connect",
                "OpenLDAP",
                "Perl",
                "PHP",
                "PostgreSQL",
                "Python",
                "React",
                "React Native",
                "Realm",
                "Redis",
                "REST",
                "Reverse Engineering",
                "Ruby on Rails",
                "RxCocoa",
                "RxDart",
                "RxSwift",
                "SAML2",
                "Scale",
                "SDK",
                "SDK Jenkins",
                "SDK Management",
                "Shaders",
                "Shell",
                "Smali",
                "SPA",
                "Spring",
                "SQL",
                "SQLite",
                "SQS",
                "SSO systems",
                "Swift",
                "Tasks",
                "Terraform",
                "Third-party SDK’s",
                "Tomcat",
                "TypeScript",
                "UI",
                "Unity",
                "Unity 3D",
                "Unreal Engine UE",
                "UX",
                "Various analytics",
                "VirtualBox",
                "Visual Studio",
                "VR",
                "Web based services",
                "Web development",
                "Wildfly",
                "Xcode",
                "XMPP",
                "XR",
                "Xamarin",
                "XML"
            ]
        }
    },
    //Art and Animation Category
    {
        "Art & Animation": {
            specialities: [
                "2D Art",
                "3D Art ",
                "Technical Art",
                "Animation",
                "Character Art",
                "Weapon Art",
                "Concept Art",
                "Environment Art",
                "Video Art",
                "Marketing Art",
                "UI Art",
                "Video Production",
                "Creative Management",
                "Creative Production",
                "Art Direction",
                "Lightning Art", 
                "Art Production",
                "Visual Effects (VFX)",
                "Vechicles Art", 
                "Material Art"
            ],
            skills: [
                "2D",
                "2D Modeling",
                "3D",
                "3D Modeling",
                "Adobe Creative Suite",
                "Adobe Illustrator",
                "Adobe Photoshop",
                "Adobe Premier Pro",
                "Advertising",
                "After Effects",
                "Anatomy",
                "Blender",
                "Character Animation",
                "Character Design",
                "Character Rigging",
                "Concept Development",
                "Creative Strategy",
                "CelAction", 
                "Digital Marketing",
                "Digital Painting",
                "Digital Sculpting",
                "Drawing", 
                "Environment",
                "Filming",
                "Film Production",
                "Final Cut Pro",
                "Game Art",
                "Game Design",
                "Game Development (GameDev)",
                "Graphic Design",
                "Design",
                "Marketing",
                "Maya",
                "MEL",
                "Motion Graphics",
                "Modeling", 
                "Mudbox",
                "Multimedia",
                "Nuke", 
                "Online Marketing",
                "Photography",
                "Rigging",
                "Screenwriting",
                "Social Media",
                "Sculpting",
                "Storyboarding",
                "Substance Painter",
                "Television",
                "Texture Painting",
                "Texturing",
                "Traditional Animation",
                "TVPaint", 
                "ToonBoom Harmony",
                "Unity",
                "Unreal Engine", 
                "User Interface",
                "UV Mapping",
                "Video",
                "Video Editing",
                "Video Post-Production",
                "Video Production",
                "ZBrush",
                "Marmoset Toolbag",
                "Props Animation",
                "3Ds Max",
                "Quixel",
                "Spine",
                "3D Max",
                "3Ds Max", 
                "Houdini",
                "Visual Effects",
                "Material",
                "Lighting",
                "Vehicle",
                "Perspective"
                
            ]
        }
    },
    {
        "UI & UX Design": {
            specialities: [
                "Concept Design",
                "(UI) User Interface Design",
                "(UX) User Experience Design",
                "Graphic Design",
                "Visual Effects Design",
                "Motion Graphics Design",
                "Web Design",
                "Interaction Design IxD",
                "Information Architecture IA",
                "Art Design",
                "3C Design",
                "Visual Effects Design"
            ],
            skills: [
                "Adobe Creative Suite",
                "Adobe Illustrator",
                "Adobe InDesign",
                "Adobe Photoshop",
                "Adobe Premiere Pro",
                "Adobe XD", 
                "After Effects",
                "Animation",
                "Art Direction",
                "AutoCAD",
                "Axure",
                "Balsamiq",
                "C++",
                "C#",
                "Cinema 4D",
                "Compositing",
                "Computer Animation",
                "Concept Development",
                "Craft", 
                "Drawing",
                "Environment", 
                "Figma",
                "Framer X",
                "FlowMapp",
                "Gameplay",
                "Graphical User Interface GUI",
                "Illustration",
                "InVision Studio",
                "JavaScript (JS)",
                "Logo Design",
                "Maya",
                "Marvel", 
                "Menu-Driver User Interface",
                "Menu Design", 
                "3D Max", 
                "Motion Graphics",
                "Origami Studio",
                "Optimal Workshop",
                "Photography",
                "Player expereince",
                "Proto.io",
                "Prototyping",
                "Revit",
                "Scrum",
                "Sketching",
                "Typography",
                "Treejack",
                "Unity",
                "Unreal Blueprints",
                "Unreal Engine",
                "User Experience (UX)",
                "User Interface (UI)",
                "UXPin",
                "User testing",
                "Video Editing",
                "Visual Sitemaps",
                "Visual Design",
                "Voice User Interface VUI",
                "Webflow",
                "Web Design", 
                "Wireframe.cc",
                "Wireframing",
                "ZBrush",
                "Marmoset Toolbag",
                "3Ds Max",
                "Sketch", 
                "Quixel",
                "Spine"
            ]
        }
    },
    {
        "Game Design": {
            specialities: [
                "AI Design",
                "Narrative Design",
                "Game Design",
                "Level Design",
                "Economy Design",
                "Balance Design",
                "Monetization Design",
                "Concept Design",
                "Content Design",
                "System Design",
                "Visual Effects Design",
                "Gameplay Design",
                "Player Experience Design",
                "World Design",
                "Motion Design"
            ],
            skills: [
                "Adobe Creative Suite",
                "Adobe Illustrator",
                "Adobe InDesign",
                "Adobe Photoshop",
                "Adobe Premiere Pro",
                "After Effects",
                "Animation",
                "Art Direction",
                "AutoCAD",
                "AB Testing", 
                "Balancing", 
                "C++",
                "C#",
                "Cinema 4D",
                "Compositing",
                "Concept Development",
                "Construct", 
                "Construct 3",
                "Design Documentation",
                "Excel",
                "F2P Free to Play", 
                "Game Mechanics",
                "Gameplay",
                "GameMaker Studio",
                "GameMaker Studio 2",
                "Game Analytics", 
                "Godot", 
                "Illustration",
                "Information Architecture",
                "JavaScript (JS)",
                "Mathematics", 
                "Maya",
                "Motion Graphics",
                "Modeling",
                "Monetization", 
                "Photography",
                "Player expereince",
                "Player testing", 
                "Revit",
                "RPG Maker",
                "Scrum",
                "Sketching",
                "Typography",
                "Unity",
                "Unreal Blueprints",
                "Unreal Engine",
                "Video Editing",
                "ZBrush",
                "Marmoset Toolbag",
                "3Ds Max",
                "Quixel",
                "Spine"
            ]
        }
    },    
    {
        "QA & Testing": {
            specialities: [
                "Game Testing",
                "Game Test Engineering",
                "Quality Assurance (QA)",
                "Quality Assurance Analysis (QA)",
                "Quality Assurance Management (QA)",
                "Quality Assurance Engineering (QA)",
                "Game Field Test Engineering",
                "Game Field Testing",
                "Test Automation",
                "Test Architecture",
                "Test Management",
                "Quality Control (QC)",
                "Software Testing",
                "Software Quality Assurance (QA)",
                "Software Test Engineering"
            ],
            skills: [
                "Automation testing",
                "Bug Tracking",
                "Bugzilla",
                "C#",
                "C++",
                "Java",
                "Change Control",
                "Chrome Dev Tools",
                "Content Management System",
                "Continuous Improvement",
                "Corrective and Preventive Action (CAPA)",
                "Cross-broswer testing",
                "Failure Mode and Effects Analysis (FMEA)",
                "Functional Testing",
                "Game Development (GameDev)",
                "Game Testing",
                "JavaScript (JS)",
                "Jira",
                "Manual Testing",
                "MATLAB",
                "Maven",
                "Microsoft SQL Server",
                "MongoDB",
                "Puppeteer",
                "Python",
                "Quality Assurance (QA)",
                "Quality Auditing",
                "Quality Control",
                "Quality Management",
                "Quality System",
                "Regression Testing",
                "Root Cause Analysis",
                "Selenium",
                "SQL",
                "System Testing",
                "Test Management",
                "Test Planning",
                "Testing",
                "Ticket management",
                "Unity",
                "Unreal Engine",
                "Version control system",
                "Web testing",
                "Confluence",
                "Testrail",
                "MSI Afterburner",
                "CPU-Z",
                "GPU-Z",
                "Perforce",
                "PlayStation Tools",
                "Xbox Tools",
                "Apache Server",
                "Sputnik"
            ]
        }
    },
    {
        "Data & Analytics": {
            specialities: [
                "Data Analytics",
                "Big Data Analytics",
                "Machine Learning",
                "Machine Learning Engineering",
                "Data Science",
                "Big Data Development",
                "Data Architecture",
                "Data Engineering",
                "Data Management",
                "Business Intelligence (BI)",
                "Data Analytics",
                "Data Visualisation",
                "Performance Analytics",
                "Ingame Analytics",
                "Business Analytics (BA)",
                "Artificial Intelligence (AI)",
                "Product Analytics", 
                "UX Analytics",
                "System Analytics",
                "Game Analytics"
            ],
            skills: [
                "AB testing",
                "Analytics",
                "Apache Spark",
                "Artificial Intelligence (AI)",
                "AutoML",
                "BI Tools",
                "Big Data",
                "Business Intelligence (BI)",
                "Bananatag",
                "C++",
                "C#",
                "Java",
                "Cluster Analysis",
                "Coding",
                "Consumer Behaviour",
                "Confluence", 
                "Dashboards",
                "Data Analysis",
                "Data Integration",
                "Data Mining",
                "Data Modeling",
                "Data Warehousing",
                "Deep Learning",
                "Data Retrieval",
                "Databricks Lakehouse",
                "Exploratory Data Analysis",
                "Extract, Transform, Load (ETL)",
                "Free to Play (F2P)",
                "Game Insights",
                "Google Cloud Platform",
                "Google Analytics",
                "Hadoop",
                "Hive",
                "Hypothesis Testing",
                "JavaScript (JS)",
                "Looker",
                "Master Data Management",
                "Microsoft Excel",
                "Microsoft SQL Server",
                "NoSQL Database",
                "Netsuite",
                "Optimization",
                "Oasis360",
                "Player Behavior",
                "PostgreSQL",
                "Predictive Analytics",
                "Python",
                "SAS Business Intelligence",
                "Qlik Sence",
                "Qlik View",
                "R",
                "Regression",
                "Regression Analysis",
                "SQL",
                "SQL Server Analysis Services (SSAS)",
                "SQL Server Integration Services (SSIS)",
                "SQL Server Reporting Services (SSRS)",
                "Statistical Modelling",
                "Statistical Tools",
                "Statistical Programming",
                "Smartsheets",
                "Tableau",
                "Version Control Systems",
                "Data Visualization",
                "MatLab",
                "Market Analysis",
                "Power BI",
                "BigQuery", 
                "Scala",
                "Workday"
                
            ]
        }
    },
    {
        "UA & Marketing": {
            specialities: [
                "Performance Marketing Management / Strategy",
                "User Acquisition (UA) Analytics ",
                "User Acquisition (UA)",
                "User Acquisition (UA) Management",
                "Marketing Management",
                "Digital Marketing",
                "Brand Marketing",
                "Marketing Strategy",
                "Social Media Marketing",
                "Public Relations (PR)",
                "Community Management",
                "Player Support",
                "Player Experience"
            ],
            skills: [
                "Adobe Illustrator",
                "Adobe Photoshop",
                "Advertising",
                "Business Strategy",
                "Customer Service",
                "Email Marketing",
                "Financial Analysis",
                "Leadership",
                "Management",
                "Marketing",
                "Marketing Management",
                "Campaigns Management",
                "Campaigns Optimization",
                "Campaigns Execution",
                "Tableau",
                "Data Visualisation",
                "Online Advertising",
                "Online Marketing",
                "Project Management",
                "Research",
                "Social Media",
                "SQL",
                "Strategic Planning",
                "Team Management",
                "Advertising tech stack design",
                "AB Testing",
                "Ad Monetization",
                "Monetization",
                "Ad Tech",
                "Programmatic Expertise",
                "Yield Optimization",
                "Creating Specifications",
                "Creative Brief",
                "Sensor Tower",
                "Game Events Planning",
                "Firebase Analytics",
                "Growth and Tests",
                "Ad Networks",
                "Admob",
                "Chartboost",
                "Adobe XD"
            ]
        }
    },
    {
        "Production": {
            specialities: [
                "Business Analytics",
                "Product Engineering",
                "Creative Production",
                "Game Production",
                "Game Art Production",
                "Planning Engineering",
                "Project Management",
                "Product Management",
                "Technical Project Management",
                "Technical Management",
                "Tech Leading",
                "Publishing Production"
            ],
            skills: [
                "Adobe Illustrator",
                "Adobe XD",
                "Analysis",
                "AutoCAD",
                "Broadcasting",
                "Business Analysis",
                "Business Process Improvement",
                "Change Management",
                "Commercials",
                "Construction",
                "Construction Management",
                "Continuous Improvement",
                "Data Analysis",
                "Data Analytics",
                "Documentaries",
                "Engineering",
                "EPC",
                "Failure Mode and Effects Analysis (FMEA)",
                "Film",
                "Film Production",
                "Game Design",
                "Game Development (GameDev)",
                "JavaScript (JS)",
                "Lean Manufacturing",
                "Level Design",
                "Management",
                "Manufacturing",
                "Marketing",
                "Marketing Strategy",
                "Mechanical Engineering",
                "Microsoft Project",
                "Microsoft SQL Server",
                "Primavera P6",
                "Product Development",
                "Product Engineering",
                "Product Management",
                "Product Marketing",
                "Project Engineering",
                "Project Management",
                "Project Planning",
                "Requirements Analysis",
                "Requirements Gathering",
                "SQL",
                "Television",
                "Unity",
                "Unreal Engine",
                "User Experience (UX)",
                "Video",
                "Video Post-Production",
                "Video Production",
                "Visio",
                "Asana",
                "Jira",
                "Gant",
                "Scrum",
                "Agile",
                "Monetization",
                "A/B Testing",
                "Prototyping",
                "Creating Specifications",
                "Creative Brief",
                "Sensor Tower",
                "Game Events Planning"
            ]
        }
    },
    {
        "Audio & Sound": {
            specialities: [
                "Sound Design",
                "Audio Design",
                "Audio Programming",
                "Audio Engineering",
                "Audio Design Engineering",
                "Audio Production",
                "Audio Technical Desing",
                "Sound Engineering",
                "Sound Production",
                "Sound Design", 
                "Music Composing",
                "Technical Sound Design",
                "SFX Engineering", 
                "Dialogue Supervising",
                "Voice Acting"
            ],
            skills: [
                "Audio Editing",
                "Audio Engineering",
                "Audio Occlusion",
                "Audio Post Production",
                "Audio Prioritization",
                "Audio Recording",
                "Audiokinetic Wwise",
                "Broadcasting",
                "C++",
                "C#",
                "Cool Edit Pro",
                "Dialogue systems",
                "Documentaries",
                "Field Recording",
                "Film",
                "FMOD",
                "Java", 
                "Game Development (GameDev)",
                "Gameplay effects",
                "In-game dialogue",
                "Multiplayer Games",
                "Music Composition",
                "Music Production",
                "Music Systems",
                "Pro Tools",
                "Programming", 
                "Reaper",
                "Sound Design",
                "Sound Forge",
                "UI sounds",
                "Unity",
                "Unreal engine",
                "Video Editing",
                "Video Post-Production",
                "Video Production",
                "Wwise"
            ]
        }
    },
    {
        "Business & Management": {
            specialities: [
                "Management",
                "Project Management",
                "Product Management",
                "Team Leading",
                "Team Management", 
                "Business Development",
                "B2B & Publishing",
                "Outsourcing",
                "Vendors Management",
                "Tech Leading",
                "Sales",
                "Publishing"
            ],
            skills: [
                "Adobe Illustrator",
                "Adobe XD",
                "Analysis",
                "Negotiations",
                "Business Analysis",
                "Business Process Improvement",
                "Change Management",
                "Commercials",
                "Continuous Improvement",
                "Confluence", 
                "Data Analysis",
                "Documentaries",
                "Engineering",
                "Game Design",
                "Game Development (GameDev)",
                "Lean Production",
                "Level Design",
                "Marketing",
                "Marketing Strategy",
                "Mechanical Engineering",
                "Microsoft Project",
                "Microsoft SQL Server",
                "Primavera P6",
                "Publishing",
                "Product Development",
                "Product Engineering",
                "Product Management",
                "Product Marketing",
                "Project Engineering",
                "Project Management",
                "Project Planning",
                "Requirements Analysis",
                "Requirements Gathering",
                "SQL",
                "Television",
                "Unity",
                "Unreal Engine",
                "User Experience (UX)",
                "Visio",
                "Asana",
                "Jira",
                "Gant",
                "Scrum",
                "Agile",
                "Monetization",
                "AB Testing",
                "Prototyping",
                "Creative Brief",
                "Sensor Tower",
                "Game Events Planning"
            ]
        }
    },
    {
        "Other": {
            specialities: [],
            skills: []
        }
    }
];

module.exports = skillTree;
