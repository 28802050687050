function VacancyCellRenderer( params ) {
  return(
     <div className="vacancyRowCell">
        {params.data['LogoLink'] &&
            (
                <img src={params.data['LogoLink']} alt={params.data['CompanyName']}/>
            )}
         <p>{params.data['CompanyName']}</p>
    </div>
  );
}

export default VacancyCellRenderer;
