function MobileRenderer( params ) {
  return(
     <div className="mobileCell">
     <div class="mobileCell-logo">
     {params.data['LogoLink'] &&
         (
             <img src={params.data['LogoLink']} alt={params.data['CompanyName']}/>
         )}
         </div>
     <div class="mobileCell-description">
        <p>{params.data['CompanyName']}</p>
        <h3>{params.data['Title']}</h3>
        <p>{params.data['Location']}</p>

      </div>
    </div>
  );
}

export default MobileRenderer;
