function RemoteRenderer( params ) {
  return(
     <div className="remoteRowCell">
        <label><input type="checkbox"
          checked={![0,'0',null,undefined,''].includes(params.data['isRemote'])} /><span></span></label>

    </div>
  );
}

export default RemoteRenderer;
